import { Component } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ProjectTypeEnum, SpecialIssueFormsClient, SpecialIssueProgress } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { ISizeAndScheduleData } from 'promotion/src/app/project/shared/size-and-schedule/size-and-schedule-data';
import { SpecialIssueLayoutStateService } from 'promotion/src/app/project/special-issue/special-issue-layout-state.service';
import { EnumLocalizingService } from 'sc-common/core/services/enum-localizing.service';

@Component({
    selector: 'sc-special-issue-size-and-schedule',
    templateUrl: './special-issue-size-and-schedule.component.html'
})
export class SpecialIssueSizeAndScheduleComponent {
    public readonly data$: Observable<ISizeAndScheduleData>;

    constructor(
        publicFormStateService: PublicFormLayoutStateService,
        private readonly _specialIssueStateService: SpecialIssueLayoutStateService,
        specialIssueClient: SpecialIssueFormsClient,
        enumLocalize: EnumLocalizingService) {
        this.data$ = this._specialIssueStateService.model$.pipe(map(p => ({
            title: enumLocalize.getName(ProjectTypeEnum, p.projectType),
            previousStepRoute: ['../editors'],
            nextStepRoute: ['../topics'],
            sizeAndSchedule$: publicFormStateService.switchMap(publicFormId => specialIssueClient.getSizeAndSchedule(publicFormId)),
            saveAction: model => publicFormStateService.firstSwitchMap(publicFormId => specialIssueClient.updateSizeAndSchedule(publicFormId, model))
        })));
    }

    public onSizeAndScheduleSaved(progress: SpecialIssueProgress): void {
        this._specialIssueStateService.updateProgress(progress);
    }
}
