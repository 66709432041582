import { ProjectTypeEnum } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { buildEnumMapProvider } from 'sc-common';

export const projectTypeEnumMapProvider = buildEnumMapProvider(ProjectTypeEnum, getEnumText);

export function getEnumText(value: ProjectTypeEnum): string {

    switch (value) {

        case ProjectTypeEnum.Conference:
            return $localize`Conference`;

        case ProjectTypeEnum.Monograph:
            return $localize`Monograph`;

        case ProjectTypeEnum.SpecialIssue:
            return $localize`Special Issue`;
    }
}
