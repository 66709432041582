import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';

import { AppRoutingModule } from 'promotion/src/app/app-routing.module';
import { AppComponent } from 'promotion/src/app/app.component';
import { CoreModule } from 'promotion/src/app/core/core.module';
import { ConferenceFormLayoutComponent } from 'promotion/src/app/project/conference/conference-form-layout.component';
import { ConferenceEditorsComponent } from 'promotion/src/app/project/conference/editors/conference-editors.component';
import { ConferenceInformationComponent } from 'promotion/src/app/project/conference/information/conference-information.component';
import { OrganizingCommitteeComponent } from 'promotion/src/app/project/conference/organizing-committee/organizing-committee.component';
import { ConferenceReviewAndSubmitComponent } from 'promotion/src/app/project/conference/review-and-submit/conference-review-and-submit.component';
import { ConferenceSizeAndScheduleComponent } from 'promotion/src/app/project/conference/size-and-schedule/conference-size-and-schedule.component';
import { StaffComponent } from 'promotion/src/app/project/conference/staff/staff.component';
import { ConferenceTopicsComponent } from 'promotion/src/app/project/conference/topics/conference-topics.component';
import { EditorsComponent } from 'promotion/src/app/project/shared/editors/editors.component';
import { PublicFormLayoutComponent } from 'promotion/src/app/project/shared/public-form-layout/public-form-layout.component';
import { ReviewAndSubmitComponent } from 'promotion/src/app/project/shared/review-and-submit/review-and-submit.component';
import { SizeAndScheduleComponent } from 'promotion/src/app/project/shared/size-and-schedule/size-and-schedule.component';
import { StepLayoutComponent } from 'promotion/src/app/project/shared/step-layout/step-layout.component';
import { TopicsComponent } from 'promotion/src/app/project/shared/topics/topics.component';
import { SpecialIssueEditorsComponent } from 'promotion/src/app/project/special-issue/editors/special-issue-editors.component';
import { SpecialIssueInformationComponent } from 'promotion/src/app/project/special-issue/information/special-issue-information.component';
import { SpecialIssueReviewAndSubmitComponent } from 'promotion/src/app/project/special-issue/review-and-submit/special-issue-review-and-submit.component';
import { SpecialIssueSizeAndScheduleComponent } from 'promotion/src/app/project/special-issue/size-and-schedule/special-issue-size-and-schedule.component';
import { SpecialIssueFormLayoutComponent } from 'promotion/src/app/project/special-issue/special-issue-form-layout.component';
import { SpecialIssueTopicsComponent } from 'promotion/src/app/project/special-issue/topics/special-issue-topics.component';
import { ExternalSharedModule } from 'sc-external/shared/external-shared.module';

@NgModule({
    declarations: [
        AppComponent,
        ConferenceFormLayoutComponent,
        ConferenceInformationComponent,
        StepLayoutComponent,
        ConferenceTopicsComponent,
        EditorsComponent,
        TopicsComponent,
        OrganizingCommitteeComponent,
        PublicFormLayoutComponent,
        ReviewAndSubmitComponent,
        SizeAndScheduleComponent,
        SpecialIssueFormLayoutComponent,
        SpecialIssueInformationComponent,
        StaffComponent,
        ConferenceEditorsComponent,
        ConferenceSizeAndScheduleComponent,
        ConferenceReviewAndSubmitComponent,
        SpecialIssueEditorsComponent,
        SpecialIssueSizeAndScheduleComponent,
        SpecialIssueTopicsComponent,
        SpecialIssueReviewAndSubmitComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        CoreModule,
        ExternalSharedModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DividerModule,
        AccordionModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
