import { ValidatorFn, Validators } from '@angular/forms';

import { ValidationService, ValidationConstants } from 'sc-common';

export class PromotionValidators {
    public static memberName(required: boolean = true): ValidatorFn[] {

        const validators = [ValidationService.nameValidator, Validators.maxLength(ValidationConstants.Max50Length)];

        if (required) {
            validators.push(Validators.required);
        }

        return validators;
    }

    public static email(required: boolean = true): ValidatorFn[] {

        const validators = [Validators.email, Validators.maxLength(ValidationConstants.MaxEmailLength)];

        if (required) {
            validators.push(Validators.required);
        }

        return validators;
    }

    public static position(): ValidatorFn[] {
        return [ValidationService.alphaNumSymbolValidator, Validators.maxLength(ValidationConstants.Max50Length)];
    }

    public static affiliation(): ValidatorFn[] {
        return [ValidationService.alphaNumSymbolValidator, Validators.maxLength(ValidationConstants.Max200Length)];
    }
}