import { Component } from '@angular/core';

import { ConferenceFormsClient, ConferenceProgress } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceLayoutStateService } from 'promotion/src/app/project/conference/conference-layout-state.service';
import { IEditorsData } from 'promotion/src/app/project/shared/editors/editors-data';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';

@Component({
    templateUrl: './conference-editors.component.html'
})
export class ConferenceEditorsComponent {

    public readonly data: IEditorsData;

    constructor(
        publicFormStateService: PublicFormLayoutStateService,
        private readonly _conferenceStateService: ConferenceLayoutStateService,
        conferenceClient: ConferenceFormsClient) {

        this.data = {
            previousStepRoute: ['../staff'],
            nextStepRoute: ['../organizing-committee'],
            editors$: publicFormStateService.switchMap(publicFormId => conferenceClient.getEditors(publicFormId)),
            copyFromStaff: () => publicFormStateService.firstSwitchMap(publicFormId => conferenceClient.getEditorsFromStaff(publicFormId)),
            saveAction: models => publicFormStateService.firstSwitchMap(publicFormId => conferenceClient.updateEditors(publicFormId, models))
        };
    }

    public onEditorsSaved(progress: ConferenceProgress): void {
        this._conferenceStateService.updateProgress(progress);
    }
}
