import { ConferenceFrequencyEnum } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { buildEnumMapProvider } from 'sc-common';

export const conferenceFrequencyEnumMapProvider = buildEnumMapProvider(ConferenceFrequencyEnum, getEnumText);

export function getEnumText(value: ConferenceFrequencyEnum): string {

    switch (value) {

        case ConferenceFrequencyEnum.Once:
            return $localize`Once`;

        case ConferenceFrequencyEnum.Yearly:
            return $localize`Yearly`;

        case ConferenceFrequencyEnum.Biyearly:
            return $localize`Biyearly`;

        case ConferenceFrequencyEnum.Once3Years:
            return $localize`Once a 3 years`;

        case ConferenceFrequencyEnum.Once4Years:
            return $localize`Once a 4 years`;

        case ConferenceFrequencyEnum.Once5Years:
            return $localize`Once a 5 years`;
    }
}
