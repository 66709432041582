<sc-review-and-submit [data]="data"
                      (submitted)="onSubmitted($event)">
    <ng-template pTemplate="information"
                 let-model>
        <div class="grid">
            <div class="col-6 mb-4 body2Font"
                 i18n>Full Title:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.fullTitle}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Country:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.country}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Final Manuscript Submission:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.finalManuscriptSubmissionDate | date:'yyyy-MM-dd'}}</div>

            <div class="col-6 body2Font"
                 i18n>Description:</div>
            <div class="col-6 subtitle3Font">{{model.description}}</div>
        </div>
    </ng-template>
</sc-review-and-submit>
