import { Component } from '@angular/core';

import { ConferenceFormsClient, ConferenceProgress, TopicsClient } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceLayoutStateService } from 'promotion/src/app/project/conference/conference-layout-state.service';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { ITopicsData } from 'promotion/src/app/project/shared/topics/topics-data';

@Component({
    templateUrl: './conference-topics.component.html'
})
export class ConferenceTopicsComponent {

    public readonly data: ITopicsData;

    constructor(
        publicFormStateService: PublicFormLayoutStateService,
        private readonly _conferenceStateService: ConferenceLayoutStateService,
        conferenceClient: ConferenceFormsClient,
        topicsClient: TopicsClient) {

        this.data = {
            previousStepRoute: ['../size-and-schedule'],
            nextStepRoute: ['../review'],
            header: $localize`Topics`,
            topicRoots$: publicFormStateService.switchMap(publicFormId => topicsClient.getTopics(publicFormId)),
            availableJournals$: publicFormStateService.switchMap(publicFormId => topicsClient.getAvailableJournals(publicFormId)),
            model$: publicFormStateService.switchMap(publicFormId => conferenceClient.getTopics(publicFormId)),
            saveAction: model => publicFormStateService.firstSwitchMap(publicFormId => conferenceClient.updateTopics(publicFormId, model))
        };
    }

    public onTopicsSaved(progress: ConferenceProgress): void {
        this._conferenceStateService.updateProgress(progress);
    }
}
