import { FactoryProvider, InjectionToken, Type } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { Observable, tap } from "rxjs";

export const PUBLIC_FORM_EDIT_GUARD = new InjectionToken('publicFormEditGuard');

type ClientContract = {
    canEdit(id: string): Observable<boolean>;
};

export function publicFormEditGuardProvider<T extends ClientContract>(clientType: Type<T>): FactoryProvider {
    return {
        provide: PUBLIC_FORM_EDIT_GUARD,
        useFactory: (client: T, router: Router) =>
            (childRoute: ActivatedRouteSnapshot, _: RouterStateSnapshot) => {

                const publicFormId: string = childRoute.parent.params.guid;

                const reviewAndSubmitPath = 'review';

                return childRoute.routeConfig.path === reviewAndSubmitPath
                    || client.canEdit(publicFormId)
                        .pipe(tap(canEdit => canEdit || router.navigate([childRoute.parent.url[0].path, publicFormId, reviewAndSubmitPath])));

            },
        deps: [clientType, Router]
    };
};
