<sc-step-layout [data]="stepData">
    <div class="flex justify-content-between align-items-end mb-3">
        <div class="title3Font"
             i18n>{{projectType$ | async | enumLocalize:projectTypeEnum}} Information</div>
    </div>
    <form [formGroup]="form"
          (ngSubmit)="save()"
          class="sc-card p-5">
        <div class="col-12 p-fluid formgrid grid">

            <div class="field col-12 mb-4">
                <label for="fullTitle"
                       class="font-bold sc-required-input"
                       i18n>Full Title</label>
                <input id="fullTitle"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="fullTitle"
                       placeholder="Enter Full Title"
                       i18n-placeholder>
            </div>
            <div class="field col-6 mb-4">
                <label for="countryId"
                       class="font-bold"
                       i18n>Country</label>
                <p-dropdown id="countryId"
                            [itemSize]="6"
                            [options]="countries$ | async"
                            [virtualScroll]="true"
                            [showClear]="true"
                            [autoDisplayFirst]="false"
                            formControlName="countryId"
                            placeholder="Choose Your Country">
                </p-dropdown>
            </div>
            <div class="field col-6 mb-4 pb-4 pl-2">
                <label for="finalManuscriptSubmissionDate"
                       class="font-bold sc-required-input"
                       i18n>Final Manuscript Submission</label>
                <p-calendar id="finalManuscriptSubmissionDate"
                            scCalendarUtc
                            scValidatable
                            dateFormat="dd/mm/yy"
                            [showIcon]="true"
                            formControlName="finalManuscriptSubmissionDate"
                            placeholder="Custom Date"
                            i18n-placeholder>
                </p-calendar>
            </div>
            <div class="field col-12 mb-4">
                <label for="description"
                       class="font-bold"
                       i18n>Description</label>
                <textarea id="description"
                          scValidatable
                          pInputTextarea
                          formControlName="description"
                          [rows]="5"
                          [autoResize]="true"
                          type="text">
                     </textarea>
            </div>
        </div>
        <div class="body2Font"><span class="sc-required-input"></span><span i18n>&nbsp;Required Fields</span></div>

        <div class="flex justify-content-center mt-5">
            <p-button scSubmitButton
                      [requestInProcess]="requestInProcess"
                      label="Save"
                      i18n-label>
            </p-button>
        </div>
    </form>
</sc-step-layout>
