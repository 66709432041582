import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { unsavedFormDialogSettignsProvider } from 'promotion/src/app/core/dialog/unsaved-form-dialog-settings';
import { conferenceFrequencyEnumMapProvider } from 'promotion/src/app/core/enum/conference-frequency-enum-map';
import { projectTypeEnumMapProvider } from 'promotion/src/app/core/enum/project-type-enum-map';
import { staffMemberTypeEnumMapProvider } from 'promotion/src/app/core/enum/staff-member-type-enum-map';
import { API_BASE_URL } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { environment } from 'promotion/src/environments/environment';
import {
    CommonCoreModule,
    ENV_TOKEN,
    HttpErrorInterceptor,
    IdentityModule
} from 'sc-common';

@NgModule({
    imports: [
        CommonModule,
        CommonCoreModule,
        IdentityModule.forRoot([environment.apiUrl, environment.apiPrmUrl])
    ],
    exports: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: ENV_TOKEN,
            useValue: environment
        },
        {
            provide: API_BASE_URL,
            useValue: environment.apiPrmUrl
        },
        unsavedFormDialogSettignsProvider,
        projectTypeEnumMapProvider,
        staffMemberTypeEnumMapProvider,
        conferenceFrequencyEnumMapProvider
    ]
})
export class CoreModule { }
