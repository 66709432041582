<sc-step-layout [data]="data">
    <div class="title3Font mb-3">{{ data.header }}</div>

    <form [formGroup]="form"
          (ngSubmit)="save()"
          class="sc-card p-5">

        <div>
            <span class="caption1Font"
                  i18n>
                <i class="fas fa-lg fa-exclamation-circle mr-2 sc-red"></i>Please, choose at least one topic you want to publish with us.
                It will help us to evaluate your project and suggest a proper journal.</span>
        </div>

        <div class="grid my-4">
            <div class="col-7">
                <div class="flex justify-content-between align-items-end mb-3">
                    <div class="title3Font"
                         i18n>Topics</div>
                    <div *ngIf="selectedTopicIds?.length">
                        <span class="body2Font sc-grey-1"
                              i18n>Selected {{ selectedTopicIds.length }} topic(s)</span>
                    </div>
                </div>

                <p-accordion *ngIf="(data.topicRoots$ | async) as topicRoots"
                             [multiple]="true">
                    <p-accordionTab *ngFor="let topicRoot of topicRoots"
                                    [header]="topicRoot.name"
                                    [selected]="isRootSelected(topicRoot)">
                        <div class="flex flex-wrap gap-3">
                            <p-checkbox *ngFor="let topic of topicRoot.topics"
                                        name="topics"
                                        [label]="topic.name"
                                        [value]="topic.id"
                                        [formControl]="$any(form.get('topicIds'))">
                            </p-checkbox>
                        </div>
                    </p-accordionTab>
                </p-accordion>

                <div *ngIf="!topicsValid"
                     class="relative mt-3">
                    <div class="sc-validation-errors">
                        <small class="p-error"
                               i18n>Please select at least one topic.</small>
                    </div>
                </div>
            </div>

            <div *ngIf="availableJournals"
                 class="col-5 pl-5">
                <div class="p-fluid formgrid grid grid-nogutter">
                    <div class="field w-full">
                        <label for="journalId"
                               class="font-bold sc-required-input"
                               i18n>Publishing in Periodical</label>
                        <p-dropdown inputId="journalId"
                                    [options]="availableJournals"
                                    scValidatable
                                    formControlName="journalId"
                                    [autoDisplayFirst]="false"
                                    optionValue="id"
                                    optionLabel="name"
                                    placeholder="Choose Periodical"
                                    i18n-placeholder>
                        </p-dropdown>
                    </div>
                </div>

                <div *ngIf="evaluatedJournals?.length">

                    <p-divider class="my-5"></p-divider>

                    <div class="title3Font mb-3"
                         i18n>Evaluation Results</div>

                    <div class="sc-grey-card p-3">
                        <div *ngFor="let evaluatedJournal of evaluatedJournals"
                             class="mb-3">
                            <div class="body1Font">{{ evaluatedJournal.journal.name }}</div>
                            <div class="flex justify-content-between align-items-center">
                                <p-progressBar class="w-full"
                                               [value]="evaluatedJournal.score">
                                </p-progressBar>

                                <p-button (onClick)="selectJournal(evaluatedJournal.journal)"
                                          styleClass="p-button-link ml-3"
                                          label="Select"
                                          i18n-label>
                                </p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="body2Font"><span class="sc-required-input"></span><span i18n>&nbsp;Required Fields</span></div>

        <div class="flex justify-content-center mt-5">
            <p-button scSubmitButton
                      [requestInProcess]="requestInProcess"
                      label="Save"
                      i18n-label>
            </p-button>
        </div>

    </form>

</sc-step-layout>