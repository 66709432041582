import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { filter, finalize, Observable, Subject, switchMap, takeUntil } from 'rxjs';

import { ApiOrganizingCommitteeMember, ConferenceFormsClient } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceLayoutStateService } from 'promotion/src/app/project/conference/conference-layout-state.service';
import { PromotionValidators } from 'promotion/src/app/project/shared/promotion-validators';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { IStepLayoutData } from 'promotion/src/app/project/shared/step-layout/step-layout-data';
import { CommonSelectItemsService } from 'sc-common';

@Component({
    templateUrl: './organizing-committee.component.html'
})
export class OrganizingCommitteeComponent implements OnDestroy {

    public readonly stepData: IStepLayoutData = {
        previousStepRoute: ['../editors'],
        nextStepRoute: ['../size-and-schedule']
    };

    public readonly titles$: Observable<SelectItem[]>;

    public readonly form: UntypedFormGroup;

    public copyInProgress: boolean;

    public requestInProcess: boolean;

    public get members(): UntypedFormGroup[] {
        return this._getMembersFormArray().controls as UntypedFormGroup[];
    }

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _publicFormStateService: PublicFormLayoutStateService,
        private readonly _stateService: ConferenceLayoutStateService,
        private readonly _conferenceClient: ConferenceFormsClient,
        private readonly _formBuilder: UntypedFormBuilder,
        selectItemsService: CommonSelectItemsService) {

        this.titles$ = selectItemsService.memberTitles$;

        this.form = this._buildForm();

        this._publicFormStateService.switchMap(publicFormId => this._conferenceClient.getOrganizingCommittee(publicFormId))
            .pipe(takeUntil(this._destroy$))
            .subscribe(members => {
                this._getMembersFormArray().clear();
                this._addMembers(members);
            });

        this._stateService.model$
            .pipe(
                filter(info => !info.organizingCommitteeVisited),
                switchMap(info => this._conferenceClient.visitOrganizingCommittee(info.publicFormId)),
                takeUntil(this._destroy$))
            .subscribe(progress => this._stateService.updateState({ progress: progress, organizingCommitteeVisited: true }));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public copyFromStaff(): void {

        this.copyInProgress = true;

        this._publicFormStateService.firstSwitchMap(publicFormId => this._conferenceClient.getOrganizingCommitteeFromStaff(publicFormId))
            .pipe(finalize(() => this.copyInProgress = false), takeUntil(this._destroy$))
            .subscribe(members => {

                this.form.markAsDirty();
                this._addMembers(members);
            });
    }

    public deleteMember(index: number): void {

        const membersFormArray = this._getMembersFormArray();

        membersFormArray.removeAt(index);

        this.save();
    }

    public addMember(): void {
        const membersFormArray = this._getMembersFormArray();

        membersFormArray.push(this._buildMemberForm(new ApiOrganizingCommitteeMember()));
    }

    public save(): void {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
        }

        this.requestInProcess = true;

        const models: ApiOrganizingCommitteeMember[] = this._getMembersFormArray().value;

        this._publicFormStateService.firstSwitchMap(publicFormId => this._conferenceClient.updateOrganizingCommittee(publicFormId, models))
            .pipe(finalize(() => this.requestInProcess = false), takeUntil(this._destroy$))
            .subscribe(progress => this._stateService.updateProgress(progress));
    }

    private _buildForm(): UntypedFormGroup {
        return this._formBuilder.group({
            members: this._formBuilder.array([])
        });
    }

    private _buildMemberForm(member: ApiOrganizingCommitteeMember): UntypedFormGroup {

        return this._formBuilder.group({
            id: [member.id],
            title: [member.title],
            position: [member.position, PromotionValidators.position()],
            firstName: [member.firstName, PromotionValidators.memberName()],
            lastName: [member.lastName, PromotionValidators.memberName()],
            affiliation: [member.affiliation, PromotionValidators.affiliation()],
            email: [member.email, PromotionValidators.email()]
        });
    }

    private _getMembersFormArray(): UntypedFormArray {
        return this.form.get('members') as UntypedFormArray;
    }

    private _addMembers(members: ApiOrganizingCommitteeMember[]): void {
        const membersFormArray = this._getMembersFormArray();

        members.forEach(e => membersFormArray.push(this._buildMemberForm(e)));
    }
}
