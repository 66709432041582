import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { finalize, Observable, Subject, takeUntil } from 'rxjs';

import { ApiConferenceInformation, ConferenceFormsClient, ConferenceFrequencyEnum, FinalManuscriptSubmissionTypeEnum } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceLayoutStateService } from 'promotion/src/app/project/conference/conference-layout-state.service';
import { IStepLayoutData } from 'promotion/src/app/project/shared/step-layout/step-layout-data';
import { EnumLocalizingService, ValidationConstants, ValidationService, ValidatorsMessages } from 'sc-common';
import { CommonSelectItemsService } from 'sc-common/shared/services/common-select-items.service';

@Component({
    templateUrl: './conference-information.component.html'
})
export class ConferenceInformationComponent implements OnDestroy {
    public stepData: IStepLayoutData = {
        previousStepRoute: null,
        nextStepRoute: ['../staff']
    };

    public readonly form: UntypedFormGroup;

    public readonly frequencies: SelectItem[] = [];
    public readonly countries$: Observable<SelectItem[]>;
    public readonly finalManuscriptSubmissionTypeEnum = FinalManuscriptSubmissionTypeEnum;
    public requestInProcess: boolean;

    private readonly _destroy$ = new Subject<void>();
    private readonly _finalManuscriptSubmissionControlName = 'finalManuscriptSubmission';
    private readonly _finalManuscriptSubmissionDateControlName = 'finalManuscriptSubmissionDate';

    constructor(
        private readonly _stateService: ConferenceLayoutStateService,
        private readonly _conferenceClient: ConferenceFormsClient,
        private readonly _formBuilder: UntypedFormBuilder,
        enumLocalize: EnumLocalizingService,
        selectItemsService: CommonSelectItemsService) {

        this.form = this._buildForm();

        this._stateService.firstSwitchMap(cf => this._conferenceClient.getConferenceInformation(cf.publicFormId))
            .pipe(takeUntil(this._destroy$))
            .subscribe(info => this.form.reset(info));

        this.frequencies = enumLocalize.getSelectItems(ConferenceFrequencyEnum);
        this.countries$ = selectItemsService.countries$;
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public save(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        const model: ApiConferenceInformation = this.form.getRawValue();

        this._stateService.firstSwitchMap(p => this._conferenceClient.updateConferenceInformation(p.publicFormId, model))
            .pipe(finalize(() => this.requestInProcess = false), takeUntil(this._destroy$))
            .subscribe(progress => {
                this._stateService.updateProgress(progress);
            });
    }

    private _buildForm(): UntypedFormGroup {
        const form = this._formBuilder.group({
            fullTitle: [null, {
                validators: [
                    Validators.required,
                    Validators.maxLength(ValidationConstants.Max225Length),
                    ValidationService.alphaNumSymbolValidator
                ]
            }],
            shortTitle: [null, {
                validators: [
                    Validators.required,
                    Validators.maxLength(ValidationConstants.Max100Length),
                    ValidationService.alphaNumSymbolValidator]
            }],
            edition: [null, {
                validators: [
                    Validators.required,
                    Validators.maxLength(ValidationConstants.Max500Length),
                    ValidationService.alphaNumSymbolValidator]
            }],
            [this._finalManuscriptSubmissionControlName]: [null],
            [this._finalManuscriptSubmissionDateControlName]: [null],
            frequency: [null],
            period: this._formBuilder.group(
                {
                    from: [null, {
                        validators: [Validators.required]
                    }],
                    to: [null, {
                        validators: [Validators.required]
                    }]
                },
                {
                    validators: [
                        ValidationService.fromToValidator('from', 'to', ValidatorsMessages.FromIsGreaterThanTo, ValidatorsMessages.ToIsLessThanFrom)]
                }),
            countryId: [null],
            city: [null, {
                validators: [
                    Validators.required,
                    Validators.maxLength(ValidationConstants.Max50Length),
                    ValidationService.addressValidator]
            }],
            website: [null, {
                validators: [
                    Validators.required,
                    Validators.maxLength(ValidationConstants.Max100Length),
                    ValidationService.websiteValidator]
            }],
            organization: [null, {
                validators: [
                    Validators.required,
                    Validators.maxLength(ValidationConstants.Max200Length), ValidationService.alphaNumSymbolValidator]
            }],
            organizationZip: [null, {
                validators: [Validators.maxLength(ValidationConstants.Max20Length), ValidationService.alphaNumSymbolValidator]
            }],
            organizationAddress: [null, {
                validators: [
                    Validators.required,
                    Validators.maxLength(ValidationConstants.Max200Length), ValidationService.alphaNumSymbolValidator]
            }],
            description: [null, {
                validators: [ValidationService.alphaNumSymbolValidator]
            }]
        });

        const dateControl = form.get(this._finalManuscriptSubmissionDateControlName);

        form.get(this._finalManuscriptSubmissionControlName).valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((result: FinalManuscriptSubmissionTypeEnum) => {
                if (result === FinalManuscriptSubmissionTypeEnum.SpecificDate) {
                    dateControl.setValidators([Validators.required]);
                } else {
                    dateControl.setValidators([]);
                }
                dateControl.updateValueAndValidity()
            });

        return form
    }
}
