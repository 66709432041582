import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, AbstractControl, ValidationErrors } from '@angular/forms';

import { isEmpty } from 'lodash-es';

import { SelectItem } from 'primeng/api';

import { finalize, Observable, Subject, takeUntil } from 'rxjs';

import { ApiEditor } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { IEditorsData } from 'promotion/src/app/project/shared/editors/editors-data';
import { PromotionValidators } from 'promotion/src/app/project/shared/promotion-validators';
import { CommonSelectItemsService, ValidationConstants, ValidatorsMessages } from 'sc-common';

@Component({
    selector: 'sc-editors',
    templateUrl: './editors.component.html'
})
export class EditorsComponent implements OnInit, OnDestroy {

    @Input()
    public data: IEditorsData;

    @Output()
    public readonly editorsSaved = new EventEmitter<any>();

    public readonly titles$: Observable<SelectItem[]>;

    public readonly countries$: Observable<SelectItem[]>;

    public readonly form: UntypedFormGroup;

    public copyInProgress: boolean;

    public requestInProcess: boolean;

    public get editors(): UntypedFormGroup[] {
        return this._getEditorsFormArray().controls as UntypedFormGroup[];
    }

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        selectItemsService: CommonSelectItemsService) {

        this.titles$ = selectItemsService.memberTitles$;
        this.countries$ = selectItemsService.countries$;

        this.form = this._buildForm();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public ngOnInit(): void {
        this.data.editors$
            .pipe(takeUntil(this._destroy$))
            .subscribe(editors => {
                this._getEditorsFormArray().clear();
                this._addEditors(editors);
            });
    }

    public copyFromStaff(): void {

        this.copyInProgress = true;

        this.data.copyFromStaff()
            .pipe(finalize(() => this.copyInProgress = false), takeUntil(this._destroy$))
            .subscribe(editors => {

                this.form.markAsDirty();
                this._addEditors(editors);
            });
    }

    public deleteEditor(index: number): void {

        const editorsFormArray = this._getEditorsFormArray();

        editorsFormArray.removeAt(index);

        this.save();

        editorsFormArray.controls.map(ef => ef.get('email'))
            .forEach(emailControl => emailControl.updateValueAndValidity());
    }

    public addEditor(): void {
        const editorsFormArray = this._getEditorsFormArray();

        editorsFormArray.push(this._buildEditorForm(new ApiEditor()));
    }

    public save(): void {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
        }

        this.requestInProcess = true;

        const models: ApiEditor[] = this._getEditorsFormArray().value;

        this.data.saveAction(models)
            .pipe(finalize(() => this.requestInProcess = false), takeUntil(this._destroy$))
            .subscribe(progress => this.editorsSaved.emit(progress));
    }

    private _buildForm(): UntypedFormGroup {
        return this._formBuilder.group({
            editors: this._formBuilder.array([])
        });
    }

    private _buildEditorForm(editor: ApiEditor): UntypedFormGroup {

        return this._formBuilder.group({
            id: [editor.id],
            title: [editor.title],
            position: [editor.position, PromotionValidators.position()],
            firstName: [editor.firstName, PromotionValidators.memberName()],
            lastName: [editor.lastName, PromotionValidators.memberName()],
            affiliation: [editor.affiliation, PromotionValidators.affiliation()],
            countryId: [editor.countryId, [Validators.required, Validators.maxLength(ValidationConstants.CountryCodeLength)]],
            email: [editor.email, [
                ...PromotionValidators.email(),
                this._uniqueEmailValidator(this._getEditorsFormArray())]]
        });
    }

    private _getEditorsFormArray(): UntypedFormArray {
        return this.form.get('editors') as UntypedFormArray;
    }

    private _addEditors(editors: ApiEditor[]): void {
        const editorsFormArray = this._getEditorsFormArray();

        editors.forEach(e => editorsFormArray.push(this._buildEditorForm(e), { emitEvent: true }));
    }

    private _uniqueEmailValidator(editorsFormArray: UntypedFormArray): (control: AbstractControl) => ValidationErrors | null {

        return (control: AbstractControl) => {

            if (!control.value) {
                return null;
            }

            let hasDuplicates = false;

            editorsFormArray.controls.map(ef => ef.get('email'))
                .filter(e => e !== control)
                .forEach(otherEmailControl => {

                    if (otherEmailControl.value == control.value) {

                        hasDuplicates = true;
                        otherEmailControl.setErrors({ ...otherEmailControl.errors, [ValidatorsMessages.UniqueValue]: { value: control.value } });
                    } else {

                        let errors = otherEmailControl.errors;

                        if (errors) {
                            delete errors[ValidatorsMessages.UniqueValue];

                            if (isEmpty(errors)) {
                                errors = null;
                            }
                        }

                        otherEmailControl.setErrors(errors);
                    }
                });

            if (hasDuplicates) {
                return { [ValidatorsMessages.UniqueValue]: { value: control.value } };
            }

            return null;
        };
    }
}
