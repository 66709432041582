import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList, TemplateRef } from '@angular/core';

import { PrimeTemplate } from 'primeng/api';

import { Subject, takeUntil, finalize, Observable, mergeMap, map, startWith } from 'rxjs';

import { IReviewAndSubmitData, ReviewSection } from 'promotion/src/app/project/shared/review-and-submit/review-and-submit-data';

type ModelMap = {
    sections: {
        key: string;
        value: ReviewSection<any, any>;
    }[];
    canSubmit: boolean;
    isEditable: boolean;
    formIncomplete: boolean;
};

@Component({
    selector: 'sc-review-and-submit',
    templateUrl: './review-and-submit.component.html'
})
export class ReviewAndSubmitComponent implements AfterContentInit, OnDestroy {

    private readonly _destroy$ = new Subject<void>();

    private _model$: Observable<ModelMap>;

    private _readonly$: Observable<boolean>;

    @Input()
    public data: IReviewAndSubmitData<any>;

    @ContentChildren(PrimeTemplate)
    public contentTemplates: QueryList<PrimeTemplate>;

    public get isReadonly$(): Observable<boolean> {

        return this._readonly$ ??= this.data.viewInfo$.pipe(map(v => !v.isEditable), startWith(true));
    }

    public get model$(): Observable<ModelMap> {

        return this._model$ ??= this.data?.model$
            .pipe(
                mergeMap(review => this.data.viewInfo$
                    .pipe(
                        map(viewInfo => <ModelMap>(
                            {
                                sections: Object.entries(review).map(([key, value]) => ({ key, value })),
                                canSubmit: viewInfo.canSubmit,
                                formIncomplete: viewInfo.formIncomplete,
                                isEditable: viewInfo.isEditable
                            })))));
    }

    @Output()
    public readonly submitted = new EventEmitter<any>();

    public readonly contentTemplateMap = new Map<string, TemplateRef<any>>();

    public requestInProcess = false;

    constructor() {
    }

    public ngAfterContentInit(): void {

        this.contentTemplates.forEach(pt => this.contentTemplateMap.set(pt.getType(), pt.template));
    }

    public ngOnDestroy(): void {

        this._destroy$.next();
        this._destroy$.complete();
    }

    public onSubmit(): void {

        this.requestInProcess = true;

        this.data.submit$
            .pipe(
                finalize(() => this.requestInProcess = false),
                takeUntil(this._destroy$))
            .subscribe(m => this.submitted.emit(m));
    }
}
