import { Component } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiConferenceFormInfo, ConferenceProgress, ProjectTypeEnum } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceLayoutStateService } from 'promotion/src/app/project/conference/conference-layout-state.service';
import { IPublicFormLayoutData } from 'promotion/src/app/project/shared/public-form-layout/public-form-layout-data';
import { EnumLocalizingService } from 'sc-common';

@Component({
    templateUrl: 'conference-form-layout.component.html',
    providers: [ConferenceLayoutStateService]
})
export class ConferenceFormLayoutComponent {

    public readonly publicFormLayoutData$: Observable<IPublicFormLayoutData>;

    constructor(conferenceLayoutStateService: ConferenceLayoutStateService, private readonly _enumLocalize: EnumLocalizingService) {
        this.publicFormLayoutData$ = conferenceLayoutStateService.model$.pipe(map(formInfo => this._getPublicFormLayoutData(formInfo)));
    }

    private _getPublicFormLayoutData(formInfo: ApiConferenceFormInfo): IPublicFormLayoutData {

        const projectTypeName = this._enumLocalize.getName(ProjectTypeEnum, formInfo.projectType);
        const progress = formInfo.progress;

        return {
            title: $localize`${ projectTypeName } Form`,
            menuItems: [
                ...(formInfo.isEditable
                    ? [{
                        label: $localize`Conference Information`,
                        routerLink: ['information'],
                        enabled: true,
                        completed: (progress & ConferenceProgress.Information) !== 0
                    },
                    {
                        label: $localize`Staff`,
                        routerLink: ['staff'],
                        enabled: true,
                        completed: (progress & ConferenceProgress.Staff) !== 0
                    },
                    {
                        label: $localize`Editors`,
                        routerLink: ['editors'],
                        enabled: true,
                        completed: (progress & ConferenceProgress.Editors) !== 0
                    },
                    {
                        label: $localize`Organizing Committee`,
                        routerLink: ['organizing-committee'],
                        enabled: true,
                        completed: (progress & ConferenceProgress.OrganizingCommittee) !== 0
                    },
                    {
                        label: $localize`Proceedings Size and Schedule`,
                        routerLink: ['size-and-schedule'],
                        enabled: true,
                        completed: (progress & ConferenceProgress.SizeAndSchedule) !== 0
                    },
                    {
                        label: $localize`Topics`,
                        routerLink: ['topics'],
                        enabled: true,
                        completed: (progress & ConferenceProgress.Topics) !== 0
                    }]
                    : []),
                {
                    label: $localize`Review${ formInfo.canSubmit ? ' & Submit' : '' }`,
                    routerLink: ['review'],
                    enabled: true,
                    completed: (progress & ConferenceProgress.ReviewAndSubmit) !== 0
                }
            ]
        };
    }
}
