import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IStepLayoutData } from 'promotion/src/app/project/shared/step-layout/step-layout-data';

@Component({
    selector: 'sc-step-layout',
    templateUrl: './step-layout.component.html'
})
export class StepLayoutComponent {

    @Input()
    public data: IStepLayoutData;

    @Input()
    public isReadonly = false;

    public requestInProcess: boolean;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router) {
    }

    public save(): void {

    }

    public navigateBack(): void {
        this._router.navigate(this.data.previousStepRoute, { relativeTo: this._route });
    }

    public navigateNext(): void {
        this._router.navigate(this.data.nextStepRoute, { relativeTo: this._route });
    }
}
