import { Component } from '@angular/core';

import { ConferenceFormsClient, ConferenceProgress } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceLayoutStateService } from 'promotion/src/app/project/conference/conference-layout-state.service';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { ISizeAndScheduleData } from 'promotion/src/app/project/shared/size-and-schedule/size-and-schedule-data';

@Component({
    selector: 'sc-conference-size-and-schedule',
    templateUrl: './conference-size-and-schedule.component.html'
})
export class ConferenceSizeAndScheduleComponent {

    public readonly data: ISizeAndScheduleData;

    constructor(
        publicFormStateService: PublicFormLayoutStateService,
        private readonly _conferenceStateService: ConferenceLayoutStateService,
        conferenceClient: ConferenceFormsClient) {

        this.data = {
            title: $localize`Proceedings`,
            previousStepRoute: ['../organizing-committee'],
            nextStepRoute: ['../topics'],
            sizeAndSchedule$: publicFormStateService.switchMap(publicFormId => conferenceClient.getProceedingsSizeAndSchedule(publicFormId)),
            saveAction: model => publicFormStateService.firstSwitchMap(publicFormId => conferenceClient.updateProceedingsSizeAndSchedule(publicFormId, model))
        };
    }

    public onSizeAndScheduleSaved(progress: ConferenceProgress): void {
        this._conferenceStateService.updateProgress(progress);
    }
}
