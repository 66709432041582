<div class="flex flex-column h-full">
    <ng-content></ng-content>

    <div *ngIf="!isReadonly"
         class="flex justify-content-between mt-5">
        <p-button [ngStyle]="{ 'visibility': data.previousStepRoute ? 'visible': 'hidden' }"
                  [disabled]="requestInProcess"
                  (onClick)="navigateBack()"
                  styleClass="p-button-link"
                  label="Previous step"
                  i18n-label>
        </p-button>

        <p-button *ngIf="data.nextStepRoute"
                  [disabled]="requestInProcess"
                  (onClick)="navigateNext()"
                  styleClass="p-button-link"
                  label="Next step"
                  i18n-label
                  [loading]="requestInProcess">
        </p-button>
    </div>
</div>
