import { Component, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { finalize, Observable, Subject, takeUntil } from 'rxjs';

import { ApiStaffMember, ConferenceFormsClient, StaffMemberType } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceLayoutStateService } from 'promotion/src/app/project/conference/conference-layout-state.service';
import { PromotionValidators } from 'promotion/src/app/project/shared/promotion-validators';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { IStepLayoutData } from 'promotion/src/app/project/shared/step-layout/step-layout-data';
import { ValidationService, ValidationConstants, CommonSelectItemsService } from 'sc-common';

@Component({
    templateUrl: './staff.component.html'
})
export class StaffComponent implements OnDestroy {

    public readonly stepData: IStepLayoutData = {
        previousStepRoute: ['../information'],
        nextStepRoute: ['../editors']
    };

    public readonly staffMemberTypeEnum = StaffMemberType;

    public readonly form: UntypedFormGroup;

    public readonly titles$: Observable<SelectItem[]>;

    public get staffMembers(): UntypedFormGroup[] {
        return this._getStaffMembersFormArray().controls as UntypedFormGroup[];
    }

    public requestInProcess: boolean;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        publicFormStateService: PublicFormLayoutStateService,
        private readonly _stateService: ConferenceLayoutStateService,
        private readonly _conferenceClient: ConferenceFormsClient,
        private readonly _formBuilder: UntypedFormBuilder,
        selectItemsService: CommonSelectItemsService) {

        this.titles$ = selectItemsService.memberTitles$;

        this.form = this._buildForm();

        publicFormStateService.firstSwitchMap(publicFormId => this._conferenceClient.getStaff(publicFormId))
            .pipe(takeUntil(this._destroy$))
            .subscribe(staff => this._getStaffMembersFormArray().reset(staff));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public save(): void {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
        }

        this.requestInProcess = true;

        const models: ApiStaffMember[] = this._getStaffMembersFormArray().value;

        this._stateService.firstSwitchMap(p => this._conferenceClient.updateStaff(p.publicFormId, models))
            .pipe(finalize(() => this.requestInProcess = false), takeUntil(this._destroy$))
            .subscribe(progress => this._stateService.updateProgress(progress));
    }

    public isApplicant(staffMemberForm: UntypedFormGroup): boolean {
        return this.getType(staffMemberForm) === StaffMemberType.Applicant;
    }

    public isApplicantOrChief(staffMemberForm: UntypedFormGroup): boolean {

        const type = this.getType(staffMemberForm);

        return type === StaffMemberType.Applicant || type === StaffMemberType.Chief;
    }

    public getType(staffMemberForm: UntypedFormGroup): StaffMemberType {
        return staffMemberForm.get('type').value;
    }

    private _buildForm(): UntypedFormGroup {
        return this._formBuilder.group({
            staffMembers: this._formBuilder.array([
                this._buildStaffMemberForm(StaffMemberType.Applicant),
                this._buildStaffMemberForm(StaffMemberType.Chief),
                this._buildStaffMemberForm(StaffMemberType.Secretary),
                this._buildStaffMemberForm(StaffMemberType.ScientificCommitteeChair),
                this._buildStaffMemberForm(StaffMemberType.TechnicalProgramChair)
            ])
        });
    }

    private _buildStaffMemberForm(type: StaffMemberType): UntypedFormGroup {

        const fieldsRequired = type === StaffMemberType.Applicant || type === StaffMemberType.Chief;

        return this._formBuilder.group({
            type: [type],
            title: [null],
            firstName: [null, PromotionValidators.memberName(fieldsRequired)],
            lastName: [null, PromotionValidators.memberName(fieldsRequired)],
            affiliation: [null, PromotionValidators.affiliation()],
            phone: [null, [ValidationService.alphaNumSymbolValidator, Validators.maxLength(ValidationConstants.Max50Length)]],
            email: [null, PromotionValidators.email(fieldsRequired)]
        });
    }

    private _getStaffMembersFormArray(): UntypedFormArray {
        return this.form.get('staffMembers') as UntypedFormArray;
    }
}
