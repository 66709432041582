import { Component } from '@angular/core';

import { SpecialIssueFormsClient, SpecialIssueProgress, TopicsClient } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { ITopicsData } from 'promotion/src/app/project/shared/topics/topics-data';
import { SpecialIssueLayoutStateService } from 'promotion/src/app/project/special-issue/special-issue-layout-state.service';
@Component({
    templateUrl: './special-issue-topics.component.html'
})
export class SpecialIssueTopicsComponent {

    public readonly data: ITopicsData;

    constructor(
        publicFormStateService: PublicFormLayoutStateService,
        private readonly _specialIssueStateService: SpecialIssueLayoutStateService,
        specialIssueClient: SpecialIssueFormsClient,
        topicsClient: TopicsClient) {

        this.data = {
            previousStepRoute: ['../size-and-schedule'],
            nextStepRoute: ['../review'],
            header: $localize`Topics`,
            topicRoots$: publicFormStateService.switchMap(publicFormId => topicsClient.getTopics(publicFormId)),
            availableJournals$: publicFormStateService.switchMap(publicFormId => topicsClient.getAvailableJournals(publicFormId)),
            model$: publicFormStateService.switchMap(publicFormId => specialIssueClient.getTopics(publicFormId)),
            saveAction: model => publicFormStateService.firstSwitchMap(publicFormId => specialIssueClient.updateTopics(publicFormId, model))
        };
    }

    public onTopicsSaved(progress: SpecialIssueProgress): void {
        this._specialIssueStateService.updateProgress(progress);
    }
}
