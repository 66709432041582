import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { Observable, Subject, takeUntil, finalize, map } from 'rxjs';

import { ApiSpecialIssueInformation, ProjectTypeEnum, SpecialIssueFormsClient } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { IStepLayoutData } from 'promotion/src/app/project/shared/step-layout/step-layout-data';
import { SpecialIssueLayoutStateService } from 'promotion/src/app/project/special-issue/special-issue-layout-state.service';
import { CommonSelectItemsService, ValidationConstants, ValidationService } from 'sc-common';

@Component({
    templateUrl: './special-issue-information.component.html'
})
export class SpecialIssueInformationComponent implements OnDestroy {
    public readonly stepData: IStepLayoutData = {
        previousStepRoute: null,
        nextStepRoute: ['../editors']
    };

    public readonly form: UntypedFormGroup;
    public readonly countries$: Observable<SelectItem[]>;
    public readonly projectType$: Observable<ProjectTypeEnum>
    public readonly projectTypeEnum = ProjectTypeEnum;
    public requestInProcess: boolean;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _stateService: SpecialIssueLayoutStateService,
        private readonly _specialIssueClient: SpecialIssueFormsClient,
        private readonly _formBuilder: UntypedFormBuilder,
        selectItemsService: CommonSelectItemsService) {

        this.form = this._buildForm();

        this._stateService.firstSwitchMap(cf => this._specialIssueClient.getSpecialIssueInformation(cf.publicFormId))
            .pipe(takeUntil(this._destroy$))
            .subscribe(info => this.form.reset(info));
        this.countries$ = selectItemsService.countries$;
        this.projectType$ = this._stateService.model$.pipe(map(a => a.projectType));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public save(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        const model: ApiSpecialIssueInformation = this.form.getRawValue();

        this._stateService.firstSwitchMap(p => this._specialIssueClient.updateSpecialIssueInformation(p.publicFormId, model))
            .pipe(finalize(() => this.requestInProcess = false), takeUntil(this._destroy$))
            .subscribe(progress => this._stateService.updateProgress(progress));
    }

    private _buildForm(): UntypedFormGroup {
        return this._formBuilder.group({
            fullTitle: [null, {
                validators: [
                    Validators.required,
                    Validators.maxLength(ValidationConstants.Max225Length),
                    ValidationService.alphaNumSymbolValidator
                ]
            }],
            finalManuscriptSubmissionDate: [null, {
                validators: [Validators.required]
            }],
            countryId: [null],
            description: [null, {
                validators: [ValidationService.alphaNumSymbolValidator]
            }]
        });
    }
}
