<sc-step-layout [data]="stepData">
    <div class="flex justify-content-between align-items-end mb-3">
        <div class="title3Font"
             i18n>Conference Information</div>
    </div>

    <form [formGroup]="form"
          (ngSubmit)="save()"
          class="sc-card p-5">
        <div class="col-12 p-fluid formgrid grid">

            <div class="field col-12 mb-4">
                <label for="fullTitle"
                       class="font-bold sc-required-input"
                       i18n>Full Title</label>
                <input id="fullTitle"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="fullTitle"
                       placeholder="Enter Full Title"
                       i18n-placeholder>
            </div>

            <div class="field col-6 mb-4">
                <label for="shortTitle"
                       class="font-bold sc-required-input"
                       i18n>Short Title</label>
                <input id="shortTitle"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="shortTitle"
                       placeholder="Enter Short Title"
                       i18n-placeholder>
            </div>
            <div class="field col-6 mb-4">
                <label for="edition"
                       class="font-bold sc-required-input"
                       i18n>Edition</label>
                <input id="edition"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="edition"
                       placeholder="Enter Edition"
                       i18n-placeholder>
            </div>
            <p-divider class="col-12 mb-4"></p-divider>
            <label for="finalManuscriptSubmissionDate"
                   class="font-bold sc-required-input col-12 mb-3"
                   i18n>Final Manuscript Submission</label>
            <div class="field col-6 mb-4">
                <p-radioButton name="finalManuscriptSubmission"
                               id="finalManuscriptSubmission"
                               [value]="finalManuscriptSubmissionTypeEnum.SpecificDate"
                               formControlName="finalManuscriptSubmission"
                               label="Custom Date"
                               i18n-label>
                </p-radioButton>
            </div>
            <div class="field col-6  mb-4">
                <p-radioButton name="finalManuscriptSubmission"
                               [value]="finalManuscriptSubmissionTypeEnum.AfterConference"
                               formControlName="finalManuscriptSubmission"
                               label="After the Conference"
                               i18n-label>
                </p-radioButton>
            </div>
            <ng-container
                          *ngIf="form.get('finalManuscriptSubmission').value === finalManuscriptSubmissionTypeEnum.SpecificDate">
                <div class="field pb-5 col-3">
                    <p-calendar id="finalManuscriptSubmissionDate"
                                scCalendarUtc
                                scValidatable
                                dateFormat="dd/mm/yy"
                                [showIcon]="true"
                                formControlName="finalManuscriptSubmissionDate"
                                placeholder="Custom Date"
                                i18n-placeholder>
                    </p-calendar>
                </div>
                <div class="col-12">
                    <span class="caption1Font"
                          i18n>
                        <i class="fas fa-lg fa-exclamation-circle sc-red"></i>
                        Kindly take note
                        <ul>
                            <li>Under Final Manuscript the Publisher understands the reviewed papers</li>
                            <li>The Publisher needs at least three full months to finalize the publishing process</li>
                            <li>The specified date is approximate and can be changed upon your request</li>
                        </ul>
                    </span>
                </div>
            </ng-container>
            <div class=" col-9 mb-4"></div>
            <p-divider class="col-12 mb-4"></p-divider>
            <div class="col-12">
                <div class="grid"
                     formGroupName="period">
                    <label class="font-bold sc-required-input col-12 mb-2"
                           i18n>Conference period</label>
                    <div class="col-6 field pb-5">
                        <p-calendar scCalendarUtc
                                    scValidatable
                                    dateFormat="dd/mm/yy"
                                    [showIcon]="true"
                                    formControlName="from"
                                    placeholder="Start Date"
                                    i18n-placeholder>
                        </p-calendar>
                    </div>
                    <div class="col-6 field pb-5">
                        <p-calendar scCalendarUtc
                                    scValidatable
                                    dateFormat="dd/mm/yy"
                                    [showIcon]="true"
                                    formControlName="to"
                                    placeholder="End Date"
                                    i18n-placeholder>
                        </p-calendar>
                    </div>
                </div>
            </div>
            <div class="field col-6 mb-4">
                <label for="frequency"
                       class="font-bold"
                       i18n>Conference Frequency</label>
                <p-dropdown scValidatable
                            id="frequency"
                            [options]="frequencies"
                            [autoDisplayFirst]="false"
                            [showClear]="true"
                            formControlName="frequency"
                            placeholder="Choose Conference Frequency">
                </p-dropdown>
            </div>
            <div class="field col-6 mb-4"></div>
            <p-divider class="col-12 mb-4"></p-divider>
            <div class="field col-6 mb-4">
                <label for="city"
                       class="font-bold sc-required-input"
                       i18n>City</label>
                <input id="city"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="city"
                       placeholder="Enter City"
                       i18n-placeholder>
            </div>
            <div class="field col-6 mb-4">
                <label for="countryId"
                       class="font-bold"
                       i18n>Country</label>
                <p-dropdown id="countryId"
                            [itemSize]="6"
                            [options]="countries$ | async"
                            [virtualScroll]="true"
                            [showClear]="true"
                            [autoDisplayFirst]="false"
                            formControlName="countryId"
                            placeholder="Choose Your Country">
                </p-dropdown>
            </div>
            <p-divider class="col-12 mb-4"></p-divider>
            <div class="field col-6 mb-4">
                <label for="website"
                       class="font-bold sc-required-input"
                       i18n>Conference Website</label>
                <input id="website"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="website"
                       placeholder="Enter Conference Website"
                       i18n-placeholder>
            </div>
            <div class="field col-6 mb-4">
                <label for="organization"
                       class="font-bold sc-required-input"
                       i18n>Organization</label>
                <input id="organization"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="organization"
                       placeholder="Enter Organization"
                       i18n-placeholder>
            </div>
            <div class="field col-6 mb-4">
                <label for="organizationAddress"
                       class="font-bold sc-required-input"
                       i18n>Organization Address</label>
                <input id="organizationAddress"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="organizationAddress"
                       placeholder="Enter Organization Address"
                       i18n-placeholder>
            </div>
            <div class="field col-6 mb-4">
                <label for="organizationZip"
                       class="font-bold"
                       i18n>Organization ZIP</label>
                <input id="organizationZip"
                       scValidatable
                       type="text"
                       pInputText
                       formControlName="organizationZip"
                       placeholder="Enter Organization ZIP"
                       i18n-placeholder>
            </div>
            <p-divider class="col-12 mb-4"></p-divider>
            <div class="field col-12 mb-4">
                <label for="description"
                       class="font-bold"
                       i18n>Description</label>
                <textarea id="description"
                          scValidatable
                          pInputTextarea
                          formControlName="description"
                          [rows]="5"
                          [autoResize]="true"
                          type="text">
                     </textarea>
            </div>
        </div>
        <div class="body2Font"><span class="sc-required-input"></span><span i18n>&nbsp;Required Fields</span></div>

        <div class="flex justify-content-center mt-5">
            <p-button scSubmitButton
                      [requestInProcess]="requestInProcess"
                      label="Save"
                      i18n-label>
            </p-button>
        </div>
    </form>
</sc-step-layout>
