<sc-step-layout [data]="data"
                [isReadonly]="isReadonly$ | async">
    <ng-container *ngIf="model$ | async as model">
        <ng-container *ngFor="let pair of model.sections; last as isLast">
            <div class="flex justify-content-between align-content-center mb-3">
                <div class="title3Font">{{ pair.value.header }}</div>
                <p-button *ngIf="model.isEditable"
                          icon="fas fa-pen"
                          label="Edit"
                          [routerLink]="['..', pair.value.editRoute]"
                          styleClass="p-button-link p-0 h-full"></p-button>
            </div>
            <div class="sc-card p-5"
                 [ngClass]="{'mb-6': (!isLast || model.canSubmit)}"
                 [ngSwitch]="pair.key">

                <ng-container *ngSwitchCase="'editors'">
                    <ng-container *ngTemplateOutlet="editorsTpl; context: { $implicit: pair.value.sectionModel}">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'proceedingsSizeAndSchedule'">
                    <ng-container
                                  *ngTemplateOutlet="proceedingsSizeAndScheduleTpl; context: { $implicit: pair.value.sectionModel}">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'topics'">
                    <ng-container *ngTemplateOutlet="topicsReviewTpl; context: { $implicit: pair.value.sectionModel}">
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <ng-template
                                 *ngTemplateOutlet="contentTemplateMap.get(pair.key); context:{$implicit: pair.value.sectionModel}">
                    </ng-template>
                </ng-container>
            </div>
        </ng-container>
        <div *ngIf="model.canSubmit"
             class="flex flex-column align-items-center">
            <p-message *ngIf="model.formIncomplete"
                       severity="error"
                       text="Please, complete the previous steps to have an opportunity form submission."
                       class="warning-message subtitle3Font mb-3"
                       i18n-text>
            </p-message>
            <p-button scSubmitButton
                      styleClass="sc-form-submit"
                      [requestInProcess]="requestInProcess"
                      label="Submit"
                      [disabled]="model.formIncomplete"
                      (onClick)="onSubmit()"
                      i18n-label>
            </p-button>
        </div>
    </ng-container>
</sc-step-layout>

<ng-template #editorsTpl
             let-model>
    <p-table [value]="model">
        <ng-template pTemplate="header">
            <tr>
                <th i18n>Position</th>
                <th i18n>Full Name</th>
                <th i18n>Affiliation</th>
                <th i18n>Country</th>
                <th i18n>Email</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body"
                     let-record>
            <tr>
                <td>{{ record.position }}</td>
                <td>{{ record.fullName }}</td>
                <td>{{ record.affiliation }}</td>
                <td>{{ record.country }}</td>
                <td>{{ record.email }}</td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>


<ng-template #proceedingsSizeAndScheduleTpl
             let-model>
    <div class="grid">
        <div class="col-6 mb-4 body2Font"
             i18n>Estd. Publications Page count:</div>
        <div class="col-6 mb-4 text-right subtitle3Font">{{model.estimatedPublicationsPageCount}}</div>

        <div class="col-6 mb-4 body2Font"
             i18n>Estd. Publications Paper count:</div>
        <div class="col-6 mb-4 text-right subtitle3Font">{{model.estimatedPublicationsPaperCount}}</div>

        <div class="col-6 mb-4 body2Font"
             i18n>Printed Books (Soft Cover) Number (min. 10 copies):</div>
        <div class="col-6 mb-4 text-right subtitle3Font">{{model.printedBooksSoftCoverCount}}</div>

        <div class="col-6 mb-4 body2Font"
             i18n>Printed Books (Hard Cover) Number (min. 10 copies):</div>
        <div class="col-6 mb-4 text-right subtitle3Font">{{model.printedBooksHardCoverCount}}</div>

        <div class="col-6 mb-4 body2Font"
             i18n>USB sticks Number (min. 10 copies):</div>
        <div class="col-6 mb-4 text-right subtitle3Font">{{model.usbSticksCount}}</div>

        <div class="col-6 body2Font"
             i18n>Additional Comments:</div>
        <div class="col-6 text-right subtitle3Font">{{model.additionalComment}}</div>
    </div>
</ng-template>

<ng-template #topicsReviewTpl
             let-model>
    <div class="grid">

        <div *ngFor="let topic of model.topics"
             class="col-12 mb-4 subtitle3Font">{{topic}}</div>

        <div class="col-6 body2Font"
             i18n>Publishing in periodical:</div>
        <div class="col-6 subtitle3Font">{{model.journal}}</div>
    </div>
</ng-template>
