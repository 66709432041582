import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { intersection } from 'lodash-es';

import { Subject, takeUntil, finalize, combineLatest, map } from 'rxjs';

import { ApiJournal, ApiPublicFormTopics, ApiTopicRoot } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ITopicsData } from 'promotion/src/app/project/shared/topics/topics-data';

@Component({
    selector: 'sc-topics',
    templateUrl: './topics.component.html'
})
export class TopicsComponent implements OnInit, OnDestroy {

    @Input()
    public data: ITopicsData;

    @Output()
    public readonly topicsSaved = new EventEmitter<any>();

    public readonly form: UntypedFormGroup;

    public availableJournals: ApiJournal[];

    public evaluatedJournals: EvaluatedJournal[];

    public selectedTopicIds: number[];

    public get topicsValid(): boolean {

        if (!this.form.touched) {
            return true;
        }

        return this.form.get('topicIds').valid;
    }

    public requestInProcess: boolean;

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _formBuilder: UntypedFormBuilder) {
        this.form = this._buildForm();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public ngOnInit(): void {

        this.data.model$
            .pipe(takeUntil(this._destroy$))
            .subscribe(model => this.form.reset(model));

        combineLatest([this.data.availableJournals$, this.form.get('topicIds').valueChanges.pipe(map(v => v as number[]))])
            .pipe(takeUntil(this._destroy$))
            .subscribe(([journals, topicIds]) => {
                this.availableJournals = journals;
                this.selectedTopicIds = topicIds;

                this.evaluatedJournals = journals
                    .map(j => ({
                        journal: j,
                        score: Math.round((j.topicIds.filter(topicId => topicIds.includes(topicId)).length / topicIds.length) * 100)
                    }))
                    .filter(ej => ej.score > 0)
                    .sort((left, right) => right.score - left.score);
            });
    }

    public isRootSelected(root: ApiTopicRoot): boolean {
        return intersection(root.topics.map(t => t.id), this.selectedTopicIds).length > 0;
    }

    public selectJournal(journal: ApiJournal): void {
        this.form.get('journalId').setValue(journal.id);
    }

    public save(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        const model: ApiPublicFormTopics = this.form.getRawValue();

        this.data.saveAction(model)
            .pipe(finalize(() => this.requestInProcess = false), takeUntil(this._destroy$))
            .subscribe(progress => this.topicsSaved.emit(progress));
    }

    private _buildForm(): UntypedFormGroup {
        return this._formBuilder.group({
            topicIds: [null, Validators.required],
            journalId: [null, Validators.required]
        });
    }
}

type EvaluatedJournal = {
    journal: ApiJournal;

    score: number;
};
