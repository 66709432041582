import { Injectable } from '@angular/core';

import { SelectItem } from 'primeng/api';

import { map, Observable, shareReplay } from 'rxjs';

import { CountriesClient, MemberTitlesClient } from 'sc-common/core/services/open-api/shared-api-client';

@Injectable({ providedIn: 'root' })
export class CommonSelectItemsService {

    public readonly countries$: Observable<SelectItem[]>;

    public readonly memberTitles$: Observable<SelectItem[]>;

    constructor(countriesClient: CountriesClient, membersTitlesClient: MemberTitlesClient) {

        this.countries$ = countriesClient.getCountryList()
            .pipe(
                map(countries => countries.map(country => ({ label: country.name, value: country.id }))),
                shareReplay({ bufferSize: 1, refCount: true }));

        this.memberTitles$ = membersTitlesClient.getMemberTitles()
            .pipe(
                map(titles => titles.map(title => ({ label: title, value: title }))),
                shareReplay({ bufferSize: 1, refCount: true }));
    }
}
