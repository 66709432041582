import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { of } from 'rxjs';

import { BaseStateService } from 'sc-common';

@Injectable()
export class PublicFormLayoutStateService extends BaseStateService<string> {

    constructor(activatedRoute: ActivatedRoute) {
        super(activatedRoute, id => of(id), params => params.guid);
    }
}
