<div class="title2Font mb-5">
    {{ data?.title }}
</div>
<div class="flex flex-row">
    <sc-progress-menu class="flex-grow-0"
                      [items]="data?.menuItems"></sc-progress-menu>
    <div class="pl-4 sc-flex-full-height sc-menu-content">
        <router-outlet></router-outlet>
    </div>
</div>
