import { Component } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiSpecialIssueFormInfo, ProjectTypeEnum, SpecialIssueProgress } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { IPublicFormLayoutData } from 'promotion/src/app/project/shared/public-form-layout/public-form-layout-data';
import { SpecialIssueLayoutStateService } from 'promotion/src/app/project/special-issue/special-issue-layout-state.service';
import { EnumLocalizingService } from 'sc-common';

@Component({
    templateUrl: 'special-issue-form-layout.component.html',
    providers: [SpecialIssueLayoutStateService]
})
export class SpecialIssueFormLayoutComponent {

    public readonly publicFormLayoutData$: Observable<IPublicFormLayoutData>;

    constructor(specialIssueLayoutStateService: SpecialIssueLayoutStateService, private readonly _enumLocalize: EnumLocalizingService) {
        this.publicFormLayoutData$ = specialIssueLayoutStateService.model$.pipe(map(formInfo => this._getPublicFormLayoutData(formInfo)));
    }

    private _getPublicFormLayoutData(formInfo: ApiSpecialIssueFormInfo): IPublicFormLayoutData {

        const projectTypeName = this._enumLocalize.getName(ProjectTypeEnum, formInfo.projectType);
        const progress = formInfo.progress;

        return {
            title: $localize`${ projectTypeName } Form`,
            menuItems: [
                ...(formInfo.isEditable
                    ? [{
                        label: $localize`${ projectTypeName } Information`,
                        routerLink: ['information'],
                        enabled: true,
                        completed: (progress & SpecialIssueProgress.Information) !== 0
                    },
                    {
                        label: $localize`Editors`,
                        routerLink: ['editors'],
                        enabled: true,
                        completed: (progress & SpecialIssueProgress.Editors) !== 0
                    },
                    {
                        label: $localize`${ projectTypeName } Size and Schedule`,
                        routerLink: ['size-and-schedule'],
                        enabled: true,
                        completed: (progress & SpecialIssueProgress.SizeAndSchedule) !== 0
                    },
                    {
                        label: $localize`Topics`,
                        routerLink: ['topics'],
                        enabled: true,
                        completed: (progress & SpecialIssueProgress.Topics) !== 0
                    }]
                    : []),
                {
                    label: $localize`Review${ formInfo.canSubmit ? ' & Submit' : '' }`,
                    routerLink: ['review'],
                    enabled: true,
                    completed: (progress & SpecialIssueProgress.ReviewAndSubmit) !== 0
                }
            ]
        };
    }
}
