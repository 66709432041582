<sc-step-layout [data]="data">
    <div class="flex justify-content-between align-items-end mb-3">
        <div class="title3Font"
             i18n>{{data.title}} Size and Schedule</div>
    </div>

    <form [formGroup]="form"
          (ngSubmit)="save()"
          class="sc-card p-5">

        <span class="caption1Font"
              i18n>
            <i class="fas fa-lg fa-exclamation-circle mr-2 sc-red"></i>Each paper should have 6 or more pages</span>
        <div class="col-12 p-fluid formgrid grid mt-6">

            <div class="field col-6 mb-4">
                <label for="estimatedPublicationsPageCount"
                       class="font-bold sc-required-input"
                       i18n>Estd. Publications Page Count</label>
                <p-inputNumber id="estimatedPublicationsPageCount"
                               scValidatable
                               formControlName="estimatedPublicationsPageCount"
                               [useGrouping]="false"
                               [min]="6"
                               placeholder="Enter Page Count"
                               i18n-placeholder>
                </p-inputNumber>
            </div>
            <div class="field col-6 mb-4">
                <label for="estimatedPublicationsPaperCount"
                       class="font-bold sc-required-input"
                       i18n>Estd. Publications Paper Count</label>
                <p-inputNumber id="estimatedPublicationsPaperCount"
                               scValidatable
                               formControlName="estimatedPublicationsPaperCount"
                               [useGrouping]="false"
                               [min]="1"
                               placeholder="Enter Page Count"
                               i18n-placeholder>
                </p-inputNumber>
            </div>
            <div class="field col-6 mb-4">
                <label for="printedBooksSoftCoverCount"
                       class="font-bold sc-required-input"
                       i18n>Printed Books (Soft Cover) Number (min. 10 copies)</label>
                <p-inputNumber id="printedBooksSoftCoverCount"
                               scValidatable
                               formControlName="printedBooksSoftCoverCount"
                               [useGrouping]="false"
                               [min]="10"
                               placeholder="Enter Number"
                               i18n-placeholder>
                </p-inputNumber>
            </div>
            <div class="field col-6 mb-4">
                <label for="printedBooksHardCoverCount"
                       class="font-bold sc-required-input"
                       i18n>Printed Books (Hard Cover) Number (min. 10 copies)</label>
                <p-inputNumber id="printedBooksHardCoverCount"
                               scValidatable
                               formControlName="printedBooksHardCoverCount"
                               [useGrouping]="false"
                               [min]="10"
                               placeholder="Enter Number"
                               i18n-placeholder>
                </p-inputNumber>
            </div>
            <div class="field col-6 mb-4">
                <label for="usbSticksCount"
                       class="font-bold sc-required-input"
                       i18n>USB Sticks Number (min. 10 copies)</label>
                <p-inputNumber id="usbSticksCount"
                               scValidatable
                               formControlName="usbSticksCount"
                               [useGrouping]="false"
                               [min]="10"
                               placeholder="Enter Number"
                               i18n-placeholder>
                </p-inputNumber>
            </div>
            <div class="col-6"></div>
            <div class="field col-12 mb-4">
                <label for="additionalComment"
                       class="font-bold"
                       i18n>Additional Comments</label>
                <textarea id="additionalComment"
                          scValidatable
                          pInputTextarea
                          formControlName="additionalComment"
                          [rows]="5"
                          [autoResize]="true"
                          type="text">
                     </textarea>
            </div>
        </div>
        <div class="body2Font"><span class="sc-required-input"></span><span i18n>&nbsp;Required Fields</span></div>

        <div class="flex justify-content-center mt-5">
            <p-button scSubmitButton
                      [requestInProcess]="requestInProcess"
                      label="Save"
                      i18n-label>
            </p-button>
        </div>
    </form>
</sc-step-layout>
