import { Component } from '@angular/core';

import { SpecialIssueFormsClient, SpecialIssueProgress } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { IEditorsData } from 'promotion/src/app/project/shared/editors/editors-data';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { SpecialIssueLayoutStateService } from 'promotion/src/app/project/special-issue/special-issue-layout-state.service';

@Component({
    selector: 'sc-special-issue-editors',
    templateUrl: './special-issue-editors.component.html'
})
export class SpecialIssueEditorsComponent {

    public readonly data: IEditorsData;

    constructor(
        publicFormStateService: PublicFormLayoutStateService,
        private readonly _specialIssueStateService: SpecialIssueLayoutStateService,
        specialIssueClient: SpecialIssueFormsClient) {

        this.data = {
            previousStepRoute: ['../information'],
            nextStepRoute: ['../size-and-schedule'],
            editors$: publicFormStateService.switchMap(publicFormId => specialIssueClient.getEditors(publicFormId)),
            copyFromStaff: null,
            saveAction: models => publicFormStateService.firstSwitchMap(publicFormId => specialIssueClient.updateEditors(publicFormId, models))
        };
    }

    public onEditorsSaved(progress: SpecialIssueProgress): void {
        this._specialIssueStateService.updateProgress(progress);
    }
}
