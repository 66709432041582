import { Component } from '@angular/core';

import { map } from 'rxjs';

import { ApiConferenceReview, ConferenceFormsClient, ConferenceFrequencyEnum, ConferenceProgress, StaffMemberType } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceLayoutStateService } from 'promotion/src/app/project/conference/conference-layout-state.service';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { IReviewAndSubmitData, ReviewModel } from 'promotion/src/app/project/shared/review-and-submit/review-and-submit-data';
import { ApiDateRange } from 'sc-common';

@Component({
    templateUrl: './conference-review-and-submit.component.html'
})
export class ConferenceReviewAndSubmitComponent {

    public readonly data: IReviewAndSubmitData<ApiConferenceReview>;

    public readonly frequencyEnumType = ConferenceFrequencyEnum;

    public readonly staffPostionEnumType = StaffMemberType;

    constructor(
        private readonly _conferenceLayoutStateService: ConferenceLayoutStateService,
        publicFormStateService: PublicFormLayoutStateService,
        client: ConferenceFormsClient) {

        this.data = {
            previousStepRoute: ['../topics'],
            nextStepRoute: null,
            model$: publicFormStateService.switchMap(publicFormId => client.review(publicFormId)
                .pipe(
                    map(apiModel => <ReviewModel<ApiConferenceReview>>{

                        information: {
                            header: $localize`Conference Information`,
                            sectionModel: apiModel.information,
                            editRoute: 'information'
                        },
                        staff: {
                            header: $localize`Staff`,
                            sectionModel: apiModel.staff,
                            editRoute: 'staff'
                        },
                        editors: {
                            header: $localize`Editors`,
                            sectionModel: apiModel.editors,
                            editRoute: 'editors'
                        },
                        organizingCommitteeMembers: {
                            header: $localize`Organizing Committee`,
                            sectionModel: apiModel.organizingCommitteeMembers,
                            editRoute: 'organizing-committee'
                        },
                        proceedingsSizeAndSchedule: {
                            header: $localize`Proceedings Size and Schedule`,
                            sectionModel: apiModel.proceedingsSizeAndSchedule,
                            editRoute: 'size-and-schedule'
                        },
                        topics: {
                            header: $localize`Topics`,
                            sectionModel: apiModel.topics,
                            editRoute: 'topics'
                        }
                    }))),
            viewInfo$: _conferenceLayoutStateService.model$
                .pipe(
                    map(state => ({
                        formIncomplete: (state.progress & ConferenceProgress.AllStepsFinished) !== ConferenceProgress.AllStepsFinished,
                        isEditable: state.isEditable,
                        canSubmit: state.canSubmit
                    }))),
            submit$: publicFormStateService.firstSwitchMap(publicFormId => client.submit(publicFormId))
        };
    }

    public formatPeriod(period: ApiDateRange): string {

        if (!period.from || !period.to) {
            return '';
        }

        const dtFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric' });

        // Casted to any as long as there is no typings for formatRange method
        return (dtFormat as any).formatRange(period.from, period.to);
    }

    public onSubmitted(progress: ConferenceProgress): void {

        this._conferenceLayoutStateService.submit(progress);
    }
}
