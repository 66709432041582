import { StaffMemberType } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { buildEnumMapProvider } from 'sc-common';

export const staffMemberTypeEnumMapProvider = buildEnumMapProvider(StaffMemberType, getEnumText);

export function getEnumText(value: StaffMemberType): string {

    switch (value) {

        case StaffMemberType.Applicant:
            return $localize`Applicant`;

        case StaffMemberType.Chief:
            return $localize`Chief`;

        case StaffMemberType.Secretary:
            return $localize`Secretary`;

        case StaffMemberType.ScientificCommitteeChair:
            return $localize`Scientific Committee Chair`;

        case StaffMemberType.TechnicalProgramChair:
            return $localize`Technical Program Chair`;
    }
}
