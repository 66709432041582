import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { takeUntil } from 'rxjs';

import { ApiConferenceFormInfo, ConferenceFormsClient, ConferenceProgress } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { BaseStateService } from 'sc-common';

@Injectable()
export class ConferenceLayoutStateService extends BaseStateService<ApiConferenceFormInfo> {

    constructor(activatedRoute: ActivatedRoute, private readonly _client: ConferenceFormsClient) {
        super(activatedRoute, id => _client.getInfo(id), params => params.guid);
    }

    public updateProgress(progress: ConferenceProgress): void {

        console.log('progress:');
        console.log(progress);

        this.updateState({ progress });
    }

    public async submit(progress: ConferenceProgress): Promise<void> {

        this.firstSwitchMap(m => this._client.canEdit(m.publicFormId))
            .pipe(takeUntil(this._destroy$))
            .subscribe(isEditable => this.updateState({
                progress,
                isEditable,
                canSubmit: false
            }));
    }
}
