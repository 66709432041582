import { Component, HostListener } from '@angular/core';

import { UnsavedFormStateService } from 'sc-common/shared/services/unsaved-form-state.service';

@Component({
    selector: 'sc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    // We cannot customize the confirm:
    // https://www.chromestatus.com/feature/5349061406228480
    @HostListener('window:beforeunload', ['$event'])
    public unloadNotification($event: any): void {
        if (this._unsavedFormStateService.hasUnsavedForms) {
            $event.returnValue = true;
        }
    }

    constructor(private readonly _unsavedFormStateService: UnsavedFormStateService) {
    }
}
