import { Inject, Injectable, Optional } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { Observable, Observer, of } from 'rxjs';

import { UnsavedFormDialogSettings, UNSAVED_FORM_DIALOG_SETTINGS } from 'sc-common/core/models/unsaved-form-dialog-settings';
import { ScConfirmationService } from 'sc-common/shared/confirmation/confirmation-service';
import { UnsavedFormStateService } from 'sc-common/shared/services/unsaved-form-state.service';

@Injectable({ providedIn: 'root' })
export class UnsavedFormGuard implements CanDeactivate<any> {

    constructor(
        private readonly _confirmService: ScConfirmationService,
        private readonly _unsavedFormStateService: UnsavedFormStateService,
        @Inject(UNSAVED_FORM_DIALOG_SETTINGS) @Optional() private _dlgSettings: UnsavedFormDialogSettings) { }

    public canDeactivate(): boolean | Observable<boolean> {

        if (this._unsavedFormStateService.hasUnsavedForms) {

            return new Observable((observer: Observer<boolean>) => {
                this._confirmService.confirm({
                    message: $localize`If you leave this page, your changes will be lost. Do you really want to leave?`,
                    [this._dlgSettings?.useTitle ? 'title' : 'header']: $localize`You have unsaved changes`,
                    width: this._dlgSettings?.width ?? '20vw',
                    acceptLabel: $localize`Leave`,
                    rejectLabel: $localize`Stay`,
                    acceptCallback: () => {
                        observer.next(true);
                        observer.complete();
                    },
                    rejectCallback: () => {
                        observer.next(false);
                        observer.complete();
                    }
                });
            });
        }
        else {
            return of(true);
        }
    }
}
