import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Subject, takeUntil, finalize } from 'rxjs';

import { ApiProceedingsSizeAndSchedule } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ISizeAndScheduleData } from 'promotion/src/app/project/shared/size-and-schedule/size-and-schedule-data';
import { ValidationConstants, ValidationService } from 'sc-common';

@Component({
    selector: 'sc-size-and-schedule',
    templateUrl: './size-and-schedule.component.html'
})
export class SizeAndScheduleComponent implements OnDestroy, OnInit {

    @Input()
    public data: ISizeAndScheduleData;

    @Output()
    public readonly sizeAndScheduleSaved = new EventEmitter<any>();

    public readonly form: UntypedFormGroup;

    public requestInProcess: boolean;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _formBuilder: UntypedFormBuilder) {
        this.form = this._buildForm();
    }

    public ngOnInit(): void {
        this.data.sizeAndSchedule$
            .pipe(takeUntil(this._destroy$))
            .subscribe(info => this.form.reset(info));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public save(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        const model: ApiProceedingsSizeAndSchedule = this.form.getRawValue();

        this.data.saveAction(model)
            .pipe(finalize(() => this.requestInProcess = false), takeUntil(this._destroy$))
            .subscribe(progress => this.sizeAndScheduleSaved.emit(progress));
    }

    private _buildForm(): UntypedFormGroup {
        return this._formBuilder.group({
            estimatedPublicationsPaperCount: [null, {
                validators: [
                    Validators.required,
                    Validators.min(ValidationConstants.One)
                ]
            }],
            estimatedPublicationsPageCount: [null, {
                validators: [
                    Validators.required,
                    Validators.min(ValidationConstants.Min6)
                ]
            }],
            usbSticksCount: [null, {
                validators: [
                    Validators.required,
                    Validators.min(ValidationConstants.Min10)
                ]
            }],
            printedBooksSoftCoverCount: [null, {
                validators: [
                    Validators.required,
                    Validators.min(ValidationConstants.Min10)
                ]
            }],
            printedBooksHardCoverCount: [null, {
                validators: [
                    Validators.required,
                    Validators.min(ValidationConstants.Min10)
                ]
            }],
            additionalComment: [null, {
                validators: [ValidationService.alphaNumSymbolValidator]
            }]
        });
    }
}
