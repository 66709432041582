import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { publicFormEditGuardProvider, PUBLIC_FORM_EDIT_GUARD } from 'promotion/src/app/core/guards/form-edit-guard-provider';
import { ConferenceFormsClient, SpecialIssueFormsClient } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { ConferenceFormLayoutComponent } from 'promotion/src/app/project/conference/conference-form-layout.component';
import { ConferenceEditorsComponent } from 'promotion/src/app/project/conference/editors/conference-editors.component';
import { ConferenceInformationComponent } from 'promotion/src/app/project/conference/information/conference-information.component';
import { OrganizingCommitteeComponent } from 'promotion/src/app/project/conference/organizing-committee/organizing-committee.component';
import { ConferenceReviewAndSubmitComponent } from 'promotion/src/app/project/conference/review-and-submit/conference-review-and-submit.component';
import { ConferenceSizeAndScheduleComponent } from 'promotion/src/app/project/conference/size-and-schedule/conference-size-and-schedule.component';
import { StaffComponent } from 'promotion/src/app/project/conference/staff/staff.component';
import { ConferenceTopicsComponent } from 'promotion/src/app/project/conference/topics/conference-topics.component';
import { SpecialIssueEditorsComponent } from 'promotion/src/app/project/special-issue/editors/special-issue-editors.component';
import { SpecialIssueInformationComponent } from 'promotion/src/app/project/special-issue/information/special-issue-information.component';
import { SpecialIssueReviewAndSubmitComponent } from 'promotion/src/app/project/special-issue/review-and-submit/special-issue-review-and-submit.component';
import { SpecialIssueSizeAndScheduleComponent } from 'promotion/src/app/project/special-issue/size-and-schedule/special-issue-size-and-schedule.component';
import { SpecialIssueFormLayoutComponent } from 'promotion/src/app/project/special-issue/special-issue-form-layout.component';
import { SpecialIssueTopicsComponent } from 'promotion/src/app/project/special-issue/topics/special-issue-topics.component';
import { UnsavedFormGuard } from 'sc-common';
import { ForbiddenComponent } from 'sc-external/shared/components/error/forbidden/403-forbidden.component';
import { NotFoundComponent } from 'sc-external/shared/components/error/not-found/404-not-found.component';
import { ServerErrorComponent } from 'sc-external/shared/components/error/server-error/500-server-error.component';

const specialIssueRoutes: Routes = [
    {
        path: 'information',
        component: SpecialIssueInformationComponent,
        canDeactivate: [UnsavedFormGuard]
    },
    {
        path: 'editors',
        component: SpecialIssueEditorsComponent,
        canDeactivate: [UnsavedFormGuard]
    },
    {
        path: 'size-and-schedule',
        component: SpecialIssueSizeAndScheduleComponent,
        canDeactivate: [UnsavedFormGuard]
    },
    {
        path: 'topics',
        component: SpecialIssueTopicsComponent,
        canDeactivate: [UnsavedFormGuard]
    },
    {
        path: 'review',
        component: SpecialIssueReviewAndSubmitComponent
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'information'
    }
];

const routes: Routes = [
    {
        path: 'forbidden',
        component: ForbiddenComponent
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: 'server-error',
        component: ServerErrorComponent
    },
    {
        path: 'conference/:guid',
        component: ConferenceFormLayoutComponent,
        canActivateChild: [PUBLIC_FORM_EDIT_GUARD],
        providers: [publicFormEditGuardProvider(ConferenceFormsClient)],
        children: [
            {
                path: 'information',
                component: ConferenceInformationComponent,
                canDeactivate: [UnsavedFormGuard]
            },
            {
                path: 'staff',
                component: StaffComponent,
                canDeactivate: [UnsavedFormGuard]
            },
            {
                path: 'editors',
                component: ConferenceEditorsComponent,
                canDeactivate: [UnsavedFormGuard]
            },
            {
                path: 'organizing-committee',
                component: OrganizingCommitteeComponent,
                canDeactivate: [UnsavedFormGuard]
            },
            {
                path: 'size-and-schedule',
                component: ConferenceSizeAndScheduleComponent,
                canDeactivate: [UnsavedFormGuard]
            },
            {
                path: 'topics',
                component: ConferenceTopicsComponent,
                canDeactivate: [UnsavedFormGuard]
            },
            {
                path: 'review',
                component: ConferenceReviewAndSubmitComponent
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'information'
            }
        ]
    },
    {
        path: 'special-issue/:guid',
        component: SpecialIssueFormLayoutComponent,
        canActivateChild: [PUBLIC_FORM_EDIT_GUARD],
        providers: [publicFormEditGuardProvider(SpecialIssueFormsClient)],
        children: specialIssueRoutes
    },
    {
        path: 'monograph/:guid',
        component: SpecialIssueFormLayoutComponent,
        canActivateChild: [PUBLIC_FORM_EDIT_GUARD],
        providers: [publicFormEditGuardProvider(SpecialIssueFormsClient)],
        children: specialIssueRoutes
    }
];

@NgModule(
    {
        imports: [RouterModule.forRoot(routes)],
        exports: [RouterModule]
    })
export class AppRoutingModule { }
