import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { takeUntil } from 'rxjs';

import { ApiSpecialIssueFormInfo, SpecialIssueFormsClient, SpecialIssueProgress } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { BaseStateService } from 'sc-common';

@Injectable()
export class SpecialIssueLayoutStateService extends BaseStateService<ApiSpecialIssueFormInfo> {

    constructor(activatedRoute: ActivatedRoute, private readonly _client: SpecialIssueFormsClient) {
        super(activatedRoute, id => _client.getInfo(id), params => params.guid);
    }

    public updateProgress(progress: SpecialIssueProgress): void {
        this.updateState({ progress });
    }

    public async submit(progress: SpecialIssueProgress): Promise<void> {

        this.firstSwitchMap(m => this._client.canEdit(m.publicFormId))
            .pipe(takeUntil(this._destroy$))
            .subscribe(isEditable => this.updateState({
                progress,
                isEditable,
                canSubmit: false
            }));
    }
}
