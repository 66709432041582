<sc-review-and-submit [data]="data"
                      (submitted)="onSubmitted($event)">
    <ng-template pTemplate="information"
                 let-model>
        <div class="grid">
            <div class="col-6 mb-4 body2Font"
                 i18n>Full Title:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.fullTitle}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Short Title:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.shortTitle}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Edition:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.edition}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Final Manuscript Submission:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.finalManuscriptSubmissionDate | date:'yyyy-MM-dd'}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Conference Date:</div>
            <div class="col-6 mb-4 subtitle3Font">{{formatPeriod(model.period)}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>City:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.city}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Country:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.country}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Conference Website:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.website}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Organization</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.organization}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Organization Address:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.organizationAddress}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Organization ZIP:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.organizationZip}}</div>

            <div class="col-6 mb-4 body2Font"
                 i18n>Conference Frequency:</div>
            <div class="col-6 mb-4 subtitle3Font">{{model.frequency | enumLocalize: frequencyEnumType}}</div>

            <div class="col-6 body2Font"
                 i18n>Description:</div>
            <div class="col-6 subtitle3Font">{{model.description}}</div>
        </div>
    </ng-template>

    <ng-template pTemplate="staff"
                 let-model>
        <p-table [value]="model">
            <ng-template pTemplate="header">
                <tr>
                    <th i18n>Position</th>
                    <th i18n>Full Name</th>
                    <th i18n>Affiliation</th>
                    <th i18n>Email</th>
                    <th i18n>Phone</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body"
                         let-record>
                <tr>
                    <td>{{ record.type | enumLocalize: staffPostionEnumType }}</td>
                    <td>{{ record.title }} {{ record.firstName }} {{ record.lastName }}</td>
                    <td>{{ record.affiliation }}</td>
                    <td>{{ record.email }}</td>
                    <td>{{ record.phone }}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>

    <ng-template pTemplate="organizingCommitteeMembers"
                 let-model>
        <p-table [value]="model">
            <ng-template pTemplate="header">
                <tr>
                    <th i18n>Position</th>
                    <th i18n>Full Name</th>
                    <th i18n>Affiliation</th>
                    <th i18n>Email</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body"
                         let-record>
                <tr>
                    <td>{{ record.position }}</td>
                    <td>{{ record.title }} {{ record.firstName }} {{ record.lastName }}</td>
                    <td>{{ record.affiliation }}</td>
                    <td>{{ record.email }}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</sc-review-and-submit>