import { Component } from '@angular/core';

import { map } from 'rxjs';

import { ApiSpecialIssueReview, ProjectTypeEnum, SpecialIssueFormsClient, SpecialIssueProgress } from 'promotion/src/app/core/open-api/promotion-public-api-client';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';
import { IReviewAndSubmitData, ReviewModel } from 'promotion/src/app/project/shared/review-and-submit/review-and-submit-data';
import { SpecialIssueLayoutStateService } from 'promotion/src/app/project/special-issue/special-issue-layout-state.service';
import { EnumLocalizingService } from 'sc-common';

@Component({
    templateUrl: './special-issue-review-and-submit.component.html'
})
export class SpecialIssueReviewAndSubmitComponent {

    public readonly data: IReviewAndSubmitData<ApiSpecialIssueReview>;

    constructor(
        private readonly _specialIssueLayoutStateService: SpecialIssueLayoutStateService,
        enumLocalize: EnumLocalizingService,
        publicFormStateService: PublicFormLayoutStateService,
        client: SpecialIssueFormsClient
    ) {
        this.data = {
            previousStepRoute: ['../topics'],
            nextStepRoute: null,
            model$: _specialIssueLayoutStateService.firstSwitchMap(state => client.review(state.publicFormId)
                .pipe(
                    map(apiModel => <ReviewModel<ApiSpecialIssueReview>>{
                        information: {
                            header: $localize`Conference Information`,
                            sectionModel: apiModel.information,
                            editRoute: 'information'
                        },
                        editors: {
                            header: $localize`Editors`,
                            sectionModel: apiModel.editors,
                            editRoute: 'editors'
                        },
                        proceedingsSizeAndSchedule: {
                            header: $localize`${ enumLocalize.getName(ProjectTypeEnum, state.projectType) } Size and Schedule`,
                            sectionModel: apiModel.proceedingsSizeAndSchedule,
                            editRoute: 'size-and-schedule'
                        },
                        topics: {
                            header: $localize`Topics`,
                            sectionModel: apiModel.topics,
                            editRoute: 'topics'
                        }
                    }))),
            viewInfo$: _specialIssueLayoutStateService.model$
                .pipe(
                    map(state => ({
                        formIncomplete: (state.progress & SpecialIssueProgress.AllStepsFinished) !== SpecialIssueProgress.AllStepsFinished,
                        isEditable: state.isEditable,
                        canSubmit: state.canSubmit
                    }))),
            submit$: publicFormStateService.firstSwitchMap(publicFormId => client.submit(publicFormId))
        };
    }

    public onSubmitted(progress: SpecialIssueProgress): void {

        this._specialIssueLayoutStateService.submit(progress);
    }
}
