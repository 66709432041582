<sc-step-layout [data]="stepData">
    <div class="title3Font mb-3"
         i18n>Organizing Committee</div>

    <form [formGroup]="form"
          (ngSubmit)="save()"
          class="sc-card p-5">

        <div class="my-4">
            <p-button (onClick)="copyFromStaff()"
                      [loading]="copyInProgress"
                      styleClass="p-button-link"
                      icon="pi pi-copy"
                      label="Copy Members from Staff"
                      i18n-label>
            </p-button>
        </div>

        <ng-container formArrayName="members">
            <ng-container *ngFor="let memberForm of members; index as i;">
                <div [formGroup]="memberForm"
                     class="grid">

                    <p-divider *ngIf="i === 0"
                               class="col-12"></p-divider>

                    <div class="col-12 mb-3">
                        <div class="flex justify-content-between">
                            <div class="title3Font"
                                 i18n>Member #{{ i + 1 }}</div>
                            <p-button icon="fas fa-trash"
                                      styleClass="p-button-text p-button-rounded"
                                      (onClick)="deleteMember(i)"
                                      pTooltip="Delete Member"
                                      i18n-pTooltip
                                      tooltipPosition="top">
                            </p-button>
                        </div>
                    </div>

                    <div class="col-12 p-fluid formgrid grid">

                        <div class="field col-6 mb-4">
                            <label for="position"
                                   class="font-bold"
                                   i18n>Position</label>
                            <input id="position"
                                   scValidatable
                                   type="text"
                                   pInputText
                                   formControlName="position"
                                   placeholder="Enter Position"
                                   i18n-placeholder>
                        </div>

                        <div class="field col-3 mb-4">
                            <label for="title"
                                   class="font-bold"
                                   i18n>Title</label>
                            <p-dropdown id="title"
                                        [options]="titles$ | async"
                                        [showClear]="true"
                                        [autoDisplayFirst]="false"
                                        formControlName="title"
                                        placeholder="Choose title"
                                        i18n-placeholder>
                            </p-dropdown>
                        </div>

                        <div class="col-3"></div>

                        <div class="field col-6 mb-4">
                            <label for="firstName"
                                   class="font-bold sc-required-input"
                                   i18n>First Name</label>
                            <input id="firstName"
                                   scValidatable
                                   type="text"
                                   pInputText
                                   formControlName="firstName"
                                   placeholder="Enter first name"
                                   i18n-placeholder>
                        </div>

                        <div class="field col-6 mb-4">
                            <label for="lastName"
                                   class="font-bold sc-required-input"
                                   i18n>Last Name</label>
                            <input id="lastName"
                                   scValidatable
                                   type="text"
                                   pInputText
                                   formControlName="lastName"
                                   placeholder="Enter last name"
                                   i18n-placeholder>
                        </div>

                        <div class="field col-6 mb-4">
                            <label for="email"
                                   class="font-bold sc-required-input"
                                   i18n>Email</label>
                            <input id="email"
                                   scValidatable
                                   type="email"
                                   pInputText
                                   formControlName="email"
                                   placeholder="Enter email address"
                                   i18n-placeholder>
                        </div>

                        <div class="field col-12 mb-4">
                            <label for="affiliation"
                                   class="font-bold"
                                   i18n>Affiliation</label>
                            <input id="affiliation"
                                   scValidatable
                                   type="text"
                                   pInputText
                                   formControlName="affiliation"
                                   placeholder="Enter affiliation"
                                   i18n-placeholder>
                        </div>

                        <p-divider class="col-12"></p-divider>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="my-4">
            <p-button (onClick)="addMember()"
                      styleClass="p-button-link"
                      icon="pi pi-plus"
                      iconPos="left"
                      label="Add Member"
                      i18n-label>
            </p-button>
        </div>

        <ng-container *ngIf="members.length">

            <div class="body2Font"><span class="sc-required-input"></span><span i18n>&nbsp;Required Fields</span></div>

            <div class="flex justify-content-center mt-5">
                <p-button scSubmitButton
                          [requestInProcess]="requestInProcess"
                          label="Save"
                          i18n-label>
                </p-button>
            </div>

        </ng-container>

    </form>

</sc-step-layout>