<sc-step-layout [data]="stepData">
    <div class="title3Font mb-3"
         i18n>Staff</div>

    <form [formGroup]="form"
          (ngSubmit)="save()"
          class="sc-card p-5">

        <span class="caption1Font"
              i18n>
            <i class="fas fa-lg fa-exclamation-circle mr-2 sc-red"></i>Applicant person and Chief person are required.</span>

        <ng-container formArrayName="staffMembers">
            <ng-container *ngFor="let staffMemberForm of staffMembers; index as i;">
                <div [formGroup]="staffMemberForm"
                     class="grid"
                     *ngIf="{ isApplicant: isApplicant(staffMemberForm), isApplicantOrChief:isApplicantOrChief(staffMemberForm) } as member">

                    <p-divider *ngIf="i === 0"
                               class="col-12"></p-divider>

                    <div class="col-12 mb-3">
                        <div class="title3Font"
                             [ngClass]="{ 'sc-required-input': member.isApplicantOrChief }"
                             i18n>{{ getType(staffMemberForm) | enumLocalize: staffMemberTypeEnum }}</div>
                    </div>

                    <div class="col-12 p-fluid formgrid grid">

                        <div class="field col-3 mb-4">
                            <label for="title"
                                   class="font-bold"
                                   i18n>Title</label>
                            <p-dropdown id="title"
                                        [options]="titles$ | async"
                                        [showClear]="true"
                                        [autoDisplayFirst]="false"
                                        formControlName="title"
                                        placeholder="Choose title"
                                        i18n-placeholder>
                            </p-dropdown>
                        </div>

                        <div class="col-9"></div>

                        <div class="field col-6 mb-4">
                            <label for="firstName"
                                   class="font-bold"
                                   [ngClass]="{ 'sc-required-input': member.isApplicantOrChief }"
                                   i18n>First Name</label>
                            <input id="firstName"
                                   scValidatable
                                   type="text"
                                   pInputText
                                   formControlName="firstName"
                                   placeholder="Enter first name"
                                   i18n-placeholder>
                        </div>

                        <div class="field col-6 mb-4">
                            <label for="lastName"
                                   class="font-bold"
                                   [ngClass]="{ 'sc-required-input': member.isApplicantOrChief }"
                                   i18n>Last Name</label>
                            <input id="lastName"
                                   scValidatable
                                   type="text"
                                   pInputText
                                   formControlName="lastName"
                                   placeholder="Enter last name"
                                   i18n-placeholder>
                        </div>

                        <div class="field col-6 mb-4">
                            <label for="email"
                                   class="font-bold"
                                   [ngClass]="{ 'sc-required-input': member.isApplicantOrChief }"
                                   i18n>Email</label>
                            <input id="email"
                                   scValidatable
                                   type="email"
                                   pInputText
                                   formControlName="email"
                                   placeholder="Enter email address"
                                   i18n-placeholder>
                        </div>

                        <div *ngIf="member.isApplicant"
                             class="field col-6 mb-4">
                            <label for="phone"
                                   class="font-bold"
                                   i18n>Phone</label>
                            <input id="phone"
                                   scValidatable
                                   type="text"
                                   pInputText
                                   formControlName="phone"
                                   placeholder="Enter phone"
                                   i18n-placeholder>
                        </div>

                        <div class="field col-12 mb-4">
                            <label for="affiliation"
                                   class="font-bold"
                                   i18n>Affiliation</label>
                            <input id="affiliation"
                                   scValidatable
                                   type="text"
                                   pInputText
                                   formControlName="affiliation"
                                   placeholder="Enter affiliation"
                                   i18n-placeholder>
                        </div>

                        <p-divider class="col-12"></p-divider>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="body2Font"><span class="sc-required-input"></span><span i18n>&nbsp;Required Fields</span></div>

        <div class="flex justify-content-center mt-5">
            <p-button scSubmitButton
                      [requestInProcess]="requestInProcess"
                      label="Save"
                      i18n-label>
            </p-button>
        </div>

    </form>

</sc-step-layout>