import { Component, Input } from '@angular/core';

import { IPublicFormLayoutData } from 'promotion/src/app/project/shared/public-form-layout/public-form-layout-data';
import { PublicFormLayoutStateService } from 'promotion/src/app/project/shared/public-form-layout/public-from-layout-state.service';

@Component({
    selector: 'sc-public-form-layout',
    templateUrl: 'public-form-layout.component.html',
    providers: [PublicFormLayoutStateService]
})
export class PublicFormLayoutComponent {

    @Input()
    public data: IPublicFormLayoutData;
}
